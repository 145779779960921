import { SpinConfig } from '@spintec/types';

export const spinConfig: SpinConfig = {
    colorTheme: 'theme-default',
    customScrollbars: true,
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'grey-200',
            secondaryBackground: 'cyan-900',
            folded: false,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-2'
        },
        toolbar  : {
            background           : 'grey-600',
            customBackgroundColor: true,
            hidden               : false,
            position             : 'below-fixed'
        },
        footer: {
            customBackgroundColor: true,
            background: 'spin-white',
            hidden: true,
            position: 'below-fixed'
        },
        sidepanel: {
            hidden: false,
            position: 'right'
        }
    }
};