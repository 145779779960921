import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { SpinDirectivesModule } from '@spintec/directives/directives';
import { SpinPipesModule } from '@spintec/pipes/pipes.module';

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        SpinDirectivesModule,
        SpinPipesModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        SpinDirectivesModule,
        SpinPipesModule
    ]
})
export class SpinSharedModule
{
}
