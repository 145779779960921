import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GeralService {

  private TEMPLATE_VISIBLE = false;

  constructor(private authService: AuthenticationService,
    private http: HttpClient,
    private keycloakService: KeycloakService) { }

  get templateVisible() {
    return this.TEMPLATE_VISIBLE;
  }

  set templateVisible(visible) {
    this.TEMPLATE_VISIBLE = visible;
  }

  reload() {
    this.TEMPLATE_VISIBLE = false;
    setTimeout( () => {
      this.templateVisible = true;
    },500);

  }

  public consultarPrestadores() {
    return this.http.get('rs/v1/common/prestadores/search');
  }

}
