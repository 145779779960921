import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private defaultUser = '65297048000159';

    private url = 'rs/v1/papeis/';

    constructor(
        private http: HttpClient,
        private keycloakService: KeycloakService) { }

    getLoggedUserCpfCnpj(): string {
        try {
            return this.keycloakService.getUsername();
        } catch (e) {
            console.log(`Error getting logged user: ${e}. Returning default user`)
            return this.defaultUser;
        }
    }

    isOperacao(): Observable<Object> {
        const cpfCnpj = this.getLoggedUserCpfCnpj();
        return this.http.get(`${this.url}/isOperacao/${cpfCnpj}`);
    }
}
