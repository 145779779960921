import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse, HttpHeaders
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { UsuarioService } from 'app/service/usuario/usuario.service';
import { EnvironmentService } from 'app/service/environment/environment.service';
import { SpinProgressBarService } from '@spintec/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({ providedIn: 'root' })

export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    private matSnackBar: MatSnackBar,
    private progressBar: SpinProgressBarService,
    private keycloakService: KeycloakService,
    private environmentService: EnvironmentService,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService) { }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('rs/v1/pessoas/clicker/autocomplete')
      && !request.url.startsWith('rs/v1/items/autocomplete')) {
        this.progressBar.show();
        this.spinner.show();
    }


    const env: any = this.environmentService.env;
    let baseurl = env.baseurl;
    if (baseurl && baseurl !== '') {
      baseurl += '/';
    }
    
    const fornecedorCpfCnpj = this.usuarioService.fornecedorSelecionado ?
     this.usuarioService.fornecedorSelecionado.cpfCnpj : '';

    const req = request.clone(
      {
        headers: request.headers.set('fornecedor-cpfcnpj', request.url.startsWith('rs/v1/pessoas/userfornecedores')
          ? ''
          : fornecedorCpfCnpj).set('Content-Type', 'application/json')
        , url: `${baseurl}${request.url}`
      });
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.progressBar.hide();
          this.spinner.hide();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.keycloakService.logout();
          this.spinner.hide();
        }  else if (error.status === 403) {
            alert("Você logou com um usuário de operadora e será deslogado!");
            this.keycloakService.logout();
        } else {
          const err = error.error;
          if (error.status === 400 && err) {
            this.progressBar.hide();
            this.spinner.hide();
            err.errors.map(erro => {
              this.matSnackBar.open(erro.message, 'OK', {duration: 5000});
            });
            return throwError(error);
          } else {
            this.progressBar.hide();
            this.spinner.hide();
            this.matSnackBar.open('Erro de comunicação, tente novamente mais tarde!', 'OK', {duration: 5000});
            return throwError(error);
          }
        }
      })
    );

  }

}
