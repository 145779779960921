import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { SpinConfigService } from '@spintec/services/config.service';
import { SpinSidebarService } from '@spintec/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { UsuarioService } from 'app/service/usuario/usuario.service';
import { KeycloakService } from 'keycloak-angular';
import { FornecedorService } from 'app/service/fornecedor/fornecedor.service';
import { UploadService } from 'app/service/upload/upload.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    fornecedor: any = {};

    photoUrl: string = '';
    nomeFornecedor: string = '';

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _keycloakService: KeycloakService,
        private _usuarioService: UsuarioService,
        private _spinConfigService: SpinConfigService,
        private _spinSidebarService: SpinSidebarService,
        private _translateService: TranslateService,
        private fornecedorService: FornecedorService,
        private uploadService: UploadService
    ) {

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._spinConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
        this.fornecedor = this._usuarioService.fornecedorSelecionado || {};

        // TODO Alterar para retornar somente a imagem do fornecedor
        this.fornecedorService.getFornecedor(this._usuarioService.fornecedorSelecionado.cpfCnpj).subscribe((fornecedor: any) => {
            if (fornecedor) {
                if (fornecedor.pessoaFisica && fornecedor.pessoaFisica.photo) {
                    this.photoUrl = this.uploadService.findUrl('files-', fornecedor.pessoaFisica.photo.key);
                }
                if (fornecedor.pessoaFisica) {
                    this.nomeFornecedor = fornecedor.pessoaFisica.nome + ' ' + fornecedor.pessoaFisica.sobrenome;
                }
            }
            if (!this.nomeFornecedor) {
                this.nomeFornecedor = this.fornecedor.nome + ' ' + this.fornecedor.nomeFantasia;
            }
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._spinSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    public logout(): void {
        this._keycloakService.logout();
        console.log('logout');
    }
}
