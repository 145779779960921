import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    private ENV: any;

    constructor(
        private authenticationService: AuthenticationService) { }

    get env() {
        return this.ENV;
    }

    set env(env) {
        this.ENV = env;
    }

    isUsuarioRedequalis() {
        return this.authenticationService.isOperacao();
    }


    /*  return new Promise(async (resolve, reject) => {
      const observe = 'response';
      if (!this.FORNECEDOR_SELECIONADO) {
      this.http.get(this.urlPessoas + '/userfornecedores', { observe }).subscribe(res => {
      this.USER_FORNECEDORES = res.body;
      this.FORNECEDOR_SELECIONADO = this.USER_FORNECEDORES[0];
      resolve(this.FORNECEDOR_SELECIONADO);
    });
    } else {
      resolve(this.FORNECEDOR_SELECIONADO);
    }

    });*/
}
