import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SpinNavigationItem } from '@spintec/types';
import { SpinNavigationService } from '@spintec/components/navigation/navigation.service';

@Component({
    selector: 'spin-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class SpinNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: SpinNavigationItem;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _spinNavigationService: SpinNavigationService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        merge(
            this._spinNavigationService.onNavigationItemAdded,
            this._spinNavigationService.onNavigationItemUpdated,
            this._spinNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
