import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { spinAnimations } from '@spintec/animations';
import { SpinConfigService } from '@spintec/services/config.service';

@Component({
    selector: 'spin-nav-horizontal-collapsable',
    templateUrl: './collapsable.component.html',
    styleUrls: ['./collapsable.component.scss'],
    animations: spinAnimations
})
export class SpinNavHorizontalCollapsableComponent implements OnInit, OnDestroy {
    spinConfig: any;
    isOpen = false;

    @HostBinding('class')
    classes = 'nav-collapsable nav-item';

    @Input()
    item: any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _spinConfigService: SpinConfigService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._spinConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.spinConfig = config;
                }
            );
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    @HostListener('mouseenter')
    open(): void {
        this.isOpen = true;
    }

    @HostListener('mouseleave')
    close(): void {
        this.isOpen = false;
    }
}
