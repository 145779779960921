export const environment = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', './assets/environment.json');
    xhr.onload = function () {
        if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
        }
        else {
            reject("Cannot load configuration...");
        }
    };
    xhr.send();
});



/*
url: 'http://18.229.6.220:50404/auth',
realm: 'redequalis',
clientId: 'portal-fornecedor'
*/
