import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { SPIN_CONFIG } from '@spintec/services/config.service';

@NgModule()
export class SpintecModule
{
    constructor(@Optional() @SkipSelf() parentModule: SpintecModule)
    {
        if ( parentModule )
        {
            throw new Error('SpintecModule já está carregado. Importe-o apenas no AppModule;');
        }
    }

    static forRoot(config): ModuleWithProviders<SpintecModule>
    {
        return {
            ngModule : SpintecModule,
            providers: [
                {
                    provide : SPIN_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
