import { NgModule, APP_INITIALIZER, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { spinConfig } from './spin-config';
import { SpintecModule } from '@spintec/spintec.module';
import { SpinSharedModule } from '@spintec/shared.module';
import { SpinProgressBarModule, SpinSidebarModule } from '@spintec/components';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

//FCM
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {MessagingService} from './service/messaging/messaging.service';
import {AngularFireModule} from '@angular/fire';
// interceptor
import { HttpConfigInterceptor } from './interceptors/http-config.interceptor';

// Locale
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
registerLocaleData(pt);

// google maps
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

// keycloak
import { initializer } from './utils/app-init';
import { AuthGuard } from './guards/auth.guard';
import { KeycloakService, KeycloakAngularModule, KeycloakBearerInterceptor } from 'keycloak-angular';
import { EnvironmentService } from './service/environment/environment.service';
import { UsuarioService } from './service/usuario/usuario.service';

//Exportar
import { ExportService } from './service/export/export.service';

const appRoutes: Routes = [
    {
        path: 'atendimento',
        loadChildren: () => import('./main/atendimento/atendimento.module').then(m => m.AtendimentoModule)
    },
    {
        path: '**',
        redirectTo: '/atendimento/solicitacao/list',
    },
];

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        TranslateModule.forRoot(),

        // Material moment
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Key Cloak
        KeycloakAngularModule,

        SpintecModule.forRoot(spinConfig),
        SpinProgressBarModule,
        SpinSharedModule,
        SpinSidebarModule,

        // Google Maps
        AgmDirectionModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyADdo19uEEtN01ns3_hJ8bVMaociKXlD6Q',
            libraries: ['places', 'geometry']
        }),
        AngularFireModule.initializeApp({
            apiKey: "AIzaSyDolcP7sZmHTteAePSrDVECoE9wYBEgHhs",
            authDomain: "redeflix-fd80d.firebaseapp.com",
            databaseURL: "https://redeflix-fd80d.firebaseio.com",
            projectId: "redeflix-fd80d",
            storageBucket: "redeflix-fd80d.appspot.com",
            messagingSenderId: "87831460251",
            appId: "1:87831460251:web:301e8e42ebbea3c9d2093e"
        }),
        AngularFireMessagingModule,
        AngularFireAuthModule,
        // App modules
        LayoutModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        MessagingService,
        AuthGuard,
        ExportService,
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: LOCALE_ID, useValue: 'pt-BR' },

        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, EnvironmentService, UsuarioService]

        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
    ]
})
export class AppModule {
}
