import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SpinConfigService } from '@spintec/services/config.service';
import { SpinNavigationService } from '@spintec/components/navigation/navigation.service';
import { SpinSidebarService } from '@spintec/components/sidebar/sidebar.service';

@Component({
    selector     : 'navbar-horizontal-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarHorizontalStyle1Component implements OnInit, OnDestroy
{
    spinConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;


    constructor(
        private _spinConfigService: SpinConfigService,
        private _SpinNavigationService: SpinNavigationService,
        private _spinSidebarService: SpinSidebarService
    )
    {
        this._unsubscribeAll = new Subject();
    }

    
    ngOnInit(): void
    {
        // Get current navigation
        this._SpinNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._SpinNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._spinConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.spinConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
