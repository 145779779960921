import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment as environmentPromise } from '../../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';



@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    private urlPessoas = 'rs/v1/pessoas';

    public ATENDENTE: any;

    public USER_FORNECEDORES: any;

    public FORNECEDOR_SELECIONADO: any;

    constructor(
        private authService: AuthenticationService,
        private http: HttpClient,
        private keycloakService: KeycloakService) {
    }

    async isUserAtendente() {
        if (this.ATENDENTE) {
            return this.ATENDENTE;
        }
        this.ATENDENTE = false;
        const env: any = await environmentPromise;
        const userRoleAtend = env.userRoleAtend;
        const roles = this.keycloakService.getUserRoles();
        if (roles && userRoleAtend) {
            const retorno = roles.filter((item) => {
                if (item === userRoleAtend) {
                    return true;
                }
            });
            this.ATENDENTE = retorno && retorno.length > 0;
        }
        return this.ATENDENTE;
    }

    get fornecedorSelecionado() {
        return this.FORNECEDOR_SELECIONADO;
    }

    set fornecedorSelecionado(fornecedor) {
        this.FORNECEDOR_SELECIONADO = fornecedor;
    }

    get fornecedores() {
        return this.USER_FORNECEDORES;
    }

    set fornecedores(fornecedores) {
        this.USER_FORNECEDORES = fornecedores;
    }

    findFornecedorSelecionado(): Promise<any> {
        return new Promise(async (resolve) => {
            const observe = 'response';
            if (!this.FORNECEDOR_SELECIONADO || this.FORNECEDOR_SELECIONADO === '') {
                this.USER_FORNECEDORES = await this.http.get(this.urlPessoas + '/userfornecedores/' + this.authService.getLoggedUserCpfCnpj(), { observe }).toPromise();
                this.USER_FORNECEDORES = this.USER_FORNECEDORES.body;
                this.FORNECEDOR_SELECIONADO = this.USER_FORNECEDORES[0];
                resolve(this.FORNECEDOR_SELECIONADO);
            } else {
                resolve(this.FORNECEDOR_SELECIONADO);
            }
        });
    }

    public trocarSenha(body, success) {
        this.http.put('rs/v1/usuarios/trocarsenha', body)
            .subscribe((a) => {
                if (success) {
                    success();
                }
            });
    }

    // TODO tirar esse método daqui e criar um service próprio
    public consultarEspecialidades() {
        return this.http.get('rs/v1/especialidadedisponivel/search');
    }

}
