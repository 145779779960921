import { NgModule } from '@angular/core';

import { SpinIfOnDomDirective } from '@spintec/directives/spin-if-on-dom/spin-if-on-dom.directive';
import { SpinInnerScrollDirective } from '@spintec/directives/spin-inner-scroll/spin-inner-scroll.directive';
import { SpinPerfectScrollbarDirective } from '@spintec/directives/spin-perfect-scrollbar/spin-perfect-scrollbar.directive';
import { SpinMatSidenavHelperDirective, SpinMatSidenavTogglerDirective } from '@spintec/directives/spin-mat-sidenav/spin-mat-sidenav.directive';

@NgModule({
    declarations: [
        SpinIfOnDomDirective,
        SpinInnerScrollDirective,
        SpinMatSidenavHelperDirective,
        SpinMatSidenavTogglerDirective,
        SpinPerfectScrollbarDirective
    ],
    imports: [],
    exports: [
        SpinIfOnDomDirective,
        SpinInnerScrollDirective,
        SpinMatSidenavHelperDirective,
        SpinMatSidenavTogglerDirective,
        SpinPerfectScrollbarDirective
    ]
})
export class SpinDirectivesModule {
}
