import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FornecedorService {

  constructor(private http: HttpClient) { }

  public getFornecedor(cpf) {
    return this.http.get('rs/v1/fornecedor/' + cpf);
  }

  public salvarFornecedor(dadosCadastrais: any) {
    return this.http.post(`rs/v1/fornecedor/save`, dadosCadastrais);
}
}
