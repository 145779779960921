import { SpinNavigation } from '@spintec/types';

export const navigation: SpinNavigation[] = [
    /*    {
            id       : 'assinatura',
            title    : 'Assinatura',
            type     : 'group',
            children : [
                {
                    id       : 'assinatura',
                    title    : 'Assinatura',
                    type     : 'item',
                    icon     : 'assignment',
                    url      : '/assinatura',
                }
            ]
        },*/
    {
        id: 'atendimento',
        title: 'Auditoria',
        type: 'group',
        children: [
            {
                id: 'solicitacoes',
                title: 'Solicitações',
                type: 'item',
                icon: 'assignment',
                url: 'atendimento/solicitacao/list',
            },
            {
                id: 'atendimento-list',
                title: 'Atendimentos',
                type: 'item',
                icon: 'playlist_add_check',
                url: 'atendimento/list',
            },
            {
                id: 'download-forms',
                title: 'Área de Download - Formulários',
                type: 'collapsable',
                icon: 'cloud_download',
                children: [
                    {
                        id: 'auditoria-contas-download',
                        title: 'Auditoria de Contas',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/auditoria_contas.xlsx',
                    },
                    {
                        id: 'auditoria-concorrente-download',
                        title: 'Auditoria Concorrente',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/auditoria_concorrente.xlsx',
                    },
                    {
                        id: 'segunda-opiniao-download',
                        title: 'Segunda Opinião',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/segunda_opiniao.xlsx',
                    },
                    {
                        id: 'rah-internacao-download',
                        title: 'RAH Internação',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/RAH_internacao.xlsx',
                    }
                ]
            }
        ]
    },
    // {
    //     id: 'logos',
    //     title: 'Prestador',
    //     type: 'item',
    //     icon: 'playlist_add_check',
    //     url: 'atendimento/logos/list'
    // }
];
