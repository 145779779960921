import { NgModule } from '@angular/core';

import { SpinSidebarComponent } from './sidebar.component';

@NgModule({
    declarations: [
        SpinSidebarComponent
    ],
    exports     : [
        SpinSidebarComponent
    ]
})
export class SpinSidebarModule
{
}
